import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useStrings } from '../../../assets/localization/strings'
import { Applicant, CampaignInsightsUgc, ParticipantUgc } from '../../../requestModels'
import styles from '../../../style/contentCampaign/creator.module.css'
import { color } from '../../../theme'

type Props = { open: boolean; onClose: () => void; influencer: ParticipantUgc | Applicant | undefined; campaign: CampaignInsightsUgc }
export const ProductPreferencesDialog = ({ open, onClose, influencer, campaign }: Props) => {
  const strings = useStrings()
  return (
    <Dialog open={open} onClose={onClose}>
      {influencer ? (
        <div style={{ maxWidth: '400px' }}>
          <DialogTitle>
            <Grid container>
              <Grid container item xs={11} alignItems='center'>
                <Typography variant='h5'>{strings.preferences_dialog_title}</Typography>
              </Grid>
              <Grid container item xs={1} justifyContent='flex-end' alignItems='center'>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {campaign.productPreferences?.map((prod, i) => (
              <Grid container key={i}>
                <Grid item xs={12} mb={2} mt={2}>
                  <Typography variant='h5' color={color.stylinkPurple}>
                    {prod}
                  </Typography>
                </Grid>
                <Grid container item xs={2} alignItems='center'>
                  <img className={styles.influencerPicture} src={influencer?.image} alt='.' />
                </Grid>
                <Grid container item xs={10} alignItems='center' pl={1}>
                  <Typography variant='body2' sx={{ background: color.grey6, borderRadius: '10px', lineBreak: 'anywhere' }} p={1}>
                    {('productPreferences' in influencer && influencer.productPreferences?.[i]) || strings.preferences_dialog_no_answer}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </DialogContent>
        </div>
      ) : (
        <div style={{ maxWidth: '350px' }}>
          <DialogTitle>{strings.preferences_dialog_title}</DialogTitle>
          <DialogContent>{strings.preferences_dialog_empty}</DialogContent>
        </div>
      )}
    </Dialog>
  )
}

export default ProductPreferencesDialog
