/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx'
import agent from '../agent'
import { ShippingService } from '../models/GeneralTypes'
import { RootStore } from './rootStore'

class StatusStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  onSentWithShipping(campaignId: string, influencerId: string, brandId: string, shippingCode: string, shippingService: ShippingService | string) {
    this.rootStore.brandStore.finishDeliveryTodo(campaignId)
    return agent.Status.onSent({ campaignId, influencerId, brandId, shippingCode, shippingService })
  }
}

export default StatusStore
