import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Fab, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useStrings } from '../../assets/localization/strings'
import { Locale, ProductType } from '../../models/GeneralTypes'
import { usePricing } from '../../reusableUtils/pricing'
import { useCustomFieldArray } from '../../reusableUtils/useCustomFieldArray'
import { useReadonly } from '../../reusableUtils/useReadonly'
import { theme } from '../../theme'
import ReactHookFormCropperDropzone from '../common/form/ReactHookFormCropperDropzone'
import ReactHookFormRadioCardGroup from '../common/form/ReactHookFormRadioCardGroup'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'
import type { IUGCCampaign } from './usePersistedUGCForm'

const CampaignProduct = () => {
  const strings = useStrings('campaign_creation_')
  const { pricing } = usePricing()
  const { control, setValue, getValues } = useFormContext<IUGCCampaign>()
  const type = useWatch({ control, name: 'productType' })
  const locale = useWatch({ control, name: 'locale' })
  const { fields, append, remove, update } = useCustomFieldArray({ control, name: 'productPreferences', keyName: 'id' })
  const isReadonly = useReadonly('productPreferences')
  const params = useParams<{ type: 'update' | 'view' }>()
  const [hasProductPreference, setHasProductPreference] = useState<'true' | 'false'>('false')

  useEffect(() => {
    setValue('locale', type == ProductType.digital ? Locale.MONEY : Locale.DELIVERY)
  }, [type])

  useEffect(() => {
    const productPreferences = getValues('productPreferences')
    if (productPreferences && productPreferences.length > 0 && (params.type == 'view' || params.type == 'update')) {
      setHasProductPreference('true')
    }
  })

  if (pricing instanceof Error || pricing == null) return null

  const options = [
    {
      label: strings.general_producttype_physical_title,
      value: ProductType.physical,
      description: strings.general_producttype_physical_desc
    },
    { label: strings.general_producttype_digital_title, value: ProductType.digital, description: strings.general_producttype_digital_desc }
  ]
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h5'>{strings.general_product_type}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormRadioCardGroup name='productType' options={options} md={6} />
      </Grid>
      <Grid item xs={12} mt={4}>
        <Typography variant='h5'>{strings.general_product_info}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography variant='body2'>{strings.general_product_info_body}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Alert severity='info'>{strings.general_product_price_disclaimer}</Alert>
      </Grid>
      <Grid item xs={12} sm={10} mt={2} pr={2} sx={{ [theme.breakpoints.only('xs')]: { padding: '0' } }}>
        <ReactHookFormTextField name='name' label={strings.general_product_name_label} />
      </Grid>
      <Grid item xs={12} sm={2} mt={2}>
        <ReactHookFormTextField name='price' label={strings.general_product_price_label} type='number' />
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormCropperDropzone
          name='campaignImage'
          label={strings.general_campaign_image_label}
          accept='image/jpeg, image/png'
          height='186px'
          width='276px'
          aspectRatio={230 / 155}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormTextField name='link' label={strings.general_product_link_label} />
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormTextField name='description' label={strings.general_product_desc_label} multiline rows={5} />
      </Grid>
      {locale === Locale.DELIVERY && (
        <>
          <Grid item xs={12} mt={4}>
            <Typography variant='h5'>
              {strings.preferences}
              <Tooltip title={strings.preferences_tooltip}>
                <IconButton>
                  <InfoOutlinedIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <RadioGroup value={hasProductPreference} onChange={e => setHasProductPreference(e.target.value as 'true' | 'false')}>
              <FormControlLabel value='false' disabled={isReadonly} control={<Radio />} label={strings.preferences_no} />
              <FormControlLabel
                value='true'
                control={<Radio />}
                label={strings.preferences_yes}
                sx={{ [theme.breakpoints.down('sm')]: { marginBottom: '30px' } }}
                disabled={isReadonly}
              />
            </RadioGroup>
          </Grid>
          {hasProductPreference === 'true' && (
            <>
              {fields.map((field, i) => (
                <Grid container mt={2} alignItems='center' key={i}>
                  <Grid item xs={11}>
                    <TextField
                      value={field.value}
                      onChange={e => update(i, { ...field, value: e.target.value })}
                      label={strings.preferences_question(i + 1)}
                      type='outlined'
                      fullWidth
                      size='small'
                      placeholder={strings.preferences_placeh}
                      InputLabelProps={{ shrink: true }}
                      disabled={isReadonly}
                    />
                  </Grid>
                  {!isReadonly && fields.length > 1 && (
                    <Grid item xs={1} textAlign='center'>
                      <DeleteIcon fontSize='small' onClick={() => remove(i)} />
                    </Grid>
                  )}
                </Grid>
              ))}
              {!isReadonly && fields.length < 3 && (
                <Grid item xs={12} textAlign='center' mt={2}>
                  <Fab color='primary' aria-label='add' size='small' onClick={() => append({ id: `${fields.length}`, value: '' })} disabled={isReadonly}>
                    <AddIcon />
                  </Fab>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  )
}

export default CampaignProduct
