import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import options from '../../../assets/icons/ic_more.svg'
import rootStore from '../../../stores/rootStore'
import { observer } from 'mobx-react'
import { CampaignInsightsUgc, ParticipantUgc } from '../../../requestModels'
import { useStrings } from '../../../assets/localization/strings'
import { StatusInfo } from '../../../models/GeneralTypes'
import { Button, Dialog, DialogContent, DialogTitle, FormGroup, Grid } from '@mui/material'
import { Checkbox, FormControlLabel } from '@mui/material'
import { adminUsers } from '../../../config'

type Props = { influencer: ParticipantUgc; campaign: CampaignInsightsUgc; removeInfluencer(influencer: ParticipantUgc): void }
const UgcParticipantMenu = ({ influencer, campaign, removeInfluencer }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const strings = useStrings()
  const handleClose = () => setAnchorEl(null)
  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => setAnchorEl(event.currentTarget)
  const [removeOpen, setRemoveOpen] = React.useState(false)
  const [readdRemoved, setReaddRemoved] = React.useState(false)

  const handleReport = () => {
    window.location.href = strings.campaign_overview_creators_mail(rootStore.brandStore.selectedBrand?.brandName ?? '', influencer.first_name)
    handleClose()
  }

  const handleRemove = async () => {
    if (!(campaign.brandId && campaign.campaignId)) return
    try {
      console.log(readdRemoved)
      await rootStore.shopStore.removeInfluencer(campaign.brandId, campaign.campaignId, influencer.influencerId, readdRemoved)
      removeInfluencer(influencer)
      setRemoveOpen(false)
    } catch (e) {
      console.error(e)
      alert(strings.error)
    }
  }

  const showShipping = () => {
    alert('Shipping code: ' + influencer.shippingCode + '\nShipping service: ' + influencer.shippingService)
  }

  const removeDialog = () => (
    <Dialog open={removeOpen} onClose={() => setRemoveOpen(false)} maxWidth='md'>
      <DialogTitle>Willst du den Creator wirklich entfernen?</DialogTitle>
      <DialogContent>
        Willst du alle abgelehnten BewerberInnen wieder freischalten?
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={readdRemoved} onChange={() => setReaddRemoved(!readdRemoved)} />} label='Erneut freischalten' />
        </FormGroup>
        <Button onClick={() => handleRemove()}>Entfernen Bestätigen</Button>
      </DialogContent>
    </Dialog>
  )

  return (
    <div>
      <img aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick} src={options} alt='options' />
      <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleReport}>{strings.campaign_overview_creators_mail_button}</MenuItem>
        {(process.env.REACT_APP_STAGE != 'production' || adminUsers.includes(rootStore.userStore.authenticatedUser?.email ?? '')) &&
          influencer.statusInfo == StatusInfo.Accepted && (
            <>
              <MenuItem onClick={() => setRemoveOpen(true)}>{strings.campaign_overview_creators_delete_button}</MenuItem>
              {removeDialog()}
            </>
          )}
        {(process.env.REACT_APP_STAGE != 'production' || adminUsers.includes(rootStore.userStore.authenticatedUser?.email ?? '')) && (
          <MenuItem
            onClick={() => {
              alert(influencer.influencerId)
            }}>
            InfluencerId
          </MenuItem>
        )}
        {influencer.shippingCode && influencer.shippingService && <MenuItem onClick={showShipping}>{strings.campaign_overview_creators_shipping_button}</MenuItem>}
      </Menu>
    </div>
  )
}

export default observer(UgcParticipantMenu)
