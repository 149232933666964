import { Button, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { numberToEuro } from '../../../reusableUtils/Helpers'
import { useReadonly } from '../../../reusableUtils/useReadonly'
import { color } from '../../../theme'

export type IRadioCardGroupOption = { label: string; value?: number | string | boolean; description: string; price?: number }

type Props<T = number | string | boolean> = { name: string; defaultValue?: T; options: IRadioCardGroupOption[]; md?: number }
const ReactHookFormRadioCardGroup = ({ name, defaultValue, options, md = 6 }: Props) => {
  const isReadonly = useReadonly(name)
  const { control, setValue } = useFormContext()

  const value = useWatch({ control, name })

  const renderPrice = (o: IRadioCardGroupOption, selected: boolean, inTitle = false) => {
    if (o.price == null) return null
    const position = !inTitle ? { position: 'absolute', bottom: 9, right: 9 } : {}

    return (
      <Grid item xs={12} textAlign='right' sx={position}>
        <Typography sx={{ fontSize: inTitle ? '16px' : '20px', fontWeight: 600, color: selected ? color.stylinkDarkPurple : 'inherit' }} display='inline'>
          {`+ ${numberToEuro(o.price)}€`}
        </Typography>
      </Grid>
    )
  }
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioGroup {...field} onChange={() => {}}>
          <Grid container spacing={2}>
            {options.map(o => {
              const selected = o.value == value
              return (
                <Grid item key={`${o.value}`} xs={12} sm={6} md={md}>
                  <Button
                    sx={{
                      border: '1.5px solid',
                      borderColor: selected ? color.stylinkDarkPurple : color.grey3,
                      borderRadius: '10px',
                      textTransform: 'none',
                      padding: '20px',
                      paddingBottom: '35px',
                      height: '100%',
                      width: '100%',
                      alignItems: 'flex-start',
                      background: selected ? color.backgroundPurple : 'inherit'
                    }}
                    value={`${o.value}`}
                    onClick={() => setValue(name, o.value)}
                    disabled={isReadonly}>
                    <Grid container>
                      <Grid item xs={10} sx={{ fontSize: '17px', fontWeight: 600, color: color.grey2, textAlign: 'left' }} container alignItems='center'>
                        {o.label}
                      </Grid>
                      <Grid item xs={2}>
                        <Radio checked={selected} value={o.value} />
                      </Grid>
                      <Grid item xs={12} sx={{ fontSize: '14px', fontWeight: 400, color: color.grey2, textAlign: 'left' }}>
                        {o.description}
                      </Grid>
                      {renderPrice(o, false)}
                    </Grid>
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </RadioGroup>
      )}
    />
  )
}

export default ReactHookFormRadioCardGroup
