/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx'
import TagManager from 'react-gtm-module'
import agent, { Arg } from '../agent'
import { check } from '../reusableUtils/Helpers'
import { RootStore } from './rootStore'

export type Campaign = Awaited<ReturnType<typeof agent.Campaigns.getAll>>[number]
type CampaignInsights = Awaited<ReturnType<typeof agent.Campaigns.getCampaign>>

export type NewCampaign = Arg<typeof agent.Campaigns.create>
export type NewUgcCampaign = NewCampaign
class CampaignStore {
  rootStore: RootStore
  campaigns?: Campaign[]

  selectedCampaign?: CampaignInsights

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  async getCampaigns(brandId?: string) {
    if (brandId) this.campaigns = await agent.Campaigns.getAll({ brandId: brandId })
  }

  async getUgcCampaign(campaignId: string, brandId: string) {
    const data = await agent.Campaigns.getCampaign({ campaignId: campaignId, brandId: brandId })
    if ('applicants' in data) return data
  }

  updateCampaignUGC = agent.Campaigns.update

  setActive(brandId: string, campaignId: string) {
    return agent.Campaigns.setActive({ brandId: brandId, campaignId: campaignId })
  }

  reset() {
    this.campaigns = undefined
    this.selectedCampaign = undefined
  }

  async createUgcCampaign(campaign: NewUgcCampaign) {
    const newCampaign = await agent.Campaigns.create(campaign)
    const { brandId, campaignId, amount_paid, invoice_id, items } = newCampaign

    try {
      const tagManagerArgs = {
        dataLayer: {
          userId: this.rootStore.userStore.authenticatedUser?.userId,
          brandId: brandId,
          campaignId: campaignId,
          price: amount_paid / 100,
          event: 'purchase',
          transaction_id: invoice_id,
          items
        }
      }

      TagManager.dataLayer(tagManagerArgs)
    } catch (e) {
      console.error(e)
    }

    return newCampaign
  }

  manageInfluencerContent(brandId: string, campaignId: string, influencerId: string, accept: boolean, denyReasons?: string[]) {
    if (accept) return agent.UGC.approveContent({ brandId, campaignId, influencerId })
    else {
      check(denyReasons, 'Deny reasons are expected')
      return agent.UGC.giveFeedbackToContent({ brandId, campaignId, influencerId, reasons: denyReasons.filterNotNull() })
    }
  }

  getAllFinishedCreators() {
    const brandId = this.rootStore.brandStore.selectedBrand?.brandId
    check(brandId, 'Brand not selected')
    return agent.UGC.getAllFinishedCreators({ brandId })
  }

  upgradeVideo = agent.UGC.upgradeVideo
  setContentFeedback = agent.UGC.setContentFeedback
}

export default CampaignStore
