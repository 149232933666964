import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import StarIcon from '@mui/icons-material/Star'
import { LoadingButton } from '@mui/lab'
import { Box, ButtonBase, Card, CardMedia, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { analytics } from '../..'
import backArrowIcon from '../../assets/icons/ic_backArrow.svg'
import CloseButton from '../../assets/icons/ic_closeButton.svg'
import CloseOrangeIcon from '../../assets/icons/ic_closeOrange.svg'
import CloseIcon from '../../assets/icons/ic_close_black.svg'
import LightBulb from '../../assets/icons/ic_lightBulb.svg'
import OpenButton from '../../assets/icons/ic_openButton.svg'
import { useStrings } from '../../assets/localization/strings'
import { Applicant, CampaignInsightsUgc, UgcContent, UgcContentFinished } from '../../requestModels'
import { dateToAge } from '../../reusableUtils/Helpers'
import rootStore from '../../stores/rootStore'
import styles from '../../style/contentCampaign/application-card.module.css'
import { color } from '../../theme'
import LoaderButton from '../common/layout/LoaderButton'

export function instanceOfUgcContent(object: any): object is UgcContent {
  return 'contentLinks' in object
}

interface Props {
  campaign: CampaignInsightsUgc
  influencer: Applicant | UgcContent | UgcContentFinished
  content: string[] // links of videos
  acceptInfluencer: (influencerId: string) => Promise<void>
  denyInfluencer: (influencerId: string, denyText: string) => Promise<void>
  openDialog?: (influencer: UgcContent) => void
  showDenySteps: boolean
  showAge?: boolean
  horizontalButtons?: boolean
  setSelectedUgcInfluencer?: React.Dispatch<React.SetStateAction<Applicant | undefined>>
}

enum DenyScreenStep {
  closed,
  second,
  third
}

export const InfluencerCard = ({
  influencer,
  acceptInfluencer,
  denyInfluencer,
  showDenySteps,
  openDialog,
  content,
  showAge = false,
  horizontalButtons = false,
  setSelectedUgcInfluencer = undefined
}: Props) => {
  const [loading, setLoading] = useState({ loadingAccept: false, loadingDeny: false })
  const [denyScreenStep, setDenyScreenStep] = useState(DenyScreenStep.closed)
  const [detailsOpen, setDetailsOpen] = useState(false)
  const strings = useStrings()
  const denyReasons = [strings.denyReasonBadQuality, strings.denyReasonIrrelevant, strings.denyReasonBadVoicing, strings.denyReasonBadAge, strings.denyReasonOther]
  const [denyInfluencerReason, setDenyInfluencerReason] = useState(denyReasons.at(-1))
  const [denyReasonText, setDenyReasonText] = useState('')
  const [playVideo, setPlayVideo] = useState(false)
  const [selectedContent, setSelectedContent] = useState(0)
  const { city, country, first_name, birth_date } = influencer

  const onAcceptInfluencer = async (influencerId: string) => {
    loading.loadingAccept = true
    setLoading({ ...loading })

    try {
      await acceptInfluencer(influencerId)
      analytics.logEvent('accept_influencer')
    } catch (e) {
      console.error(e)
    } finally {
      loading.loadingAccept = false
      setLoading({ ...loading })
    }
  }

  const onDenyInfluencer = async (influencerId: string, dR?: string, dRText?: string) => {
    console.log(dR, dRText)
    const denyText = dR || ''
    loading.loadingDeny = true
    setLoading(loading)
    try {
      await denyInfluencer(influencerId, denyText)
      analytics.logEvent('deny_influencer')
    } catch (e) {
      console.error(e)
    } finally {
      loading.loadingDeny = false
      setLoading(loading)
      setDenyScreenStep(DenyScreenStep.closed)
    }
  }

  const generateSecondDenyStep = () => (
    <Grid container justifyContent='center'>
      <div className={styles.denyScreen}>
        <Grid container direction='row' className={styles.denyGridContainer}>
          <Grid item xs={12} className={styles.denyGridHeader}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={2}>
                <img src={CloseIcon} alt='X' className={styles.closeDenyScreenIcon} onClick={() => setDenyScreenStep(DenyScreenStep.closed)} />
              </Grid>
              <Grid item xs={10}>
                <b>{strings.influencer_dialog_deny_reason}</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {!loading.loadingDeny ? (
                denyReasons.map((reason, i) => (
                  <Grid item key={i} xs={12}>
                    <button
                      className={styles.denyReason}
                      onClick={() => {
                        setDenyInfluencerReason(reason)
                        if (reason === 'Sonstiges') {
                          setDenyScreenStep(DenyScreenStep.third)
                        } else {
                          onDenyInfluencer(influencer.influencerId, denyInfluencerReason)
                        }
                      }}>
                      <p className={styles.buttonText}>{reason}</p>
                    </button>
                  </Grid>
                ))
              ) : (
                <Grid container justifyContent='center' alignItems='center' style={{ height: '200px' }}>
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )

  const generateThirdDenyStep = () => (
    <Grid container justifyContent='center'>
      <div className={styles.denyScreenText}>
        <Grid container direction='row' className={styles.denyGridContainer}>
          <Grid item xs={12} className={styles.denyGridHeader}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={1}>
                <img src={backArrowIcon} alt='<' className={styles.backDenyScreenIcon} onClick={() => setDenyScreenStep(DenyScreenStep.second)} />
              </Grid>
              <Grid item xs={10}>
                <b>{denyInfluencerReason}</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <textarea
              placeholder={strings.deny_reason_question}
              cols={4}
              value={denyReasonText}
              onChange={event => setDenyReasonText(event.target.value)}
              className={styles.denyTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderButton
              text={strings.cancel}
              isLoading={loading.loadingDeny}
              className={styles.denyButton}
              onClick={() => onDenyInfluencer(influencer.influencerId, denyInfluencerReason, denyReasonText)}
              disabled={denyReasonText.length < 5}
              style={{ marginTop: '5px' }}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  )

  const generateGeneralInformation = (infl: Applicant | UgcContent | UgcContentFinished) => (
    <>
      <Grid container alignItems='baseline'>
        <Grid container item xs={12} p='8px 8px 0px 8px' onClick={() => setDetailsOpen(true)} alignItems='center' justifyContent='space-between'>
          {!detailsOpen && (
            <>
              <Grid item>
                <Grid container alignItems='center'>
                  <b id='influencerName' style={{ fontSize: '16px', paddingRight: '4px' }}>
                    {showAge ? `${infl.first_name}, ${dateToAge(birth_date)}` : infl.first_name}
                  </b>
                  {'averageRating' in influencer && influencer.averageRating && (
                    <>
                      <StarIcon sx={{ color: color.stylinkPurple, paddingRight: '2px' }} fontSize='small' />{' '}
                      <Typography fontWeight='bold' fontSize='12px' display='inline'>
                        {(influencer as Applicant).averageRating?.toFixed(1)}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                {setSelectedUgcInfluencer && (
                  <IconButton>
                    <img src={LightBulb} style={{ cursor: 'pointer' }} alt='?' />
                  </IconButton>
                )}
                <IconButton>
                  <img src={OpenButton} alt='<' width='16px' />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container alignItems='center' mt='5px' pr={1} pl={1} spacing={1}>
        <Grid item xs={horizontalButtons ? 12 : 8} className={styles.buttonsMobile}>
          <LoadingButton
            fullWidth
            variant='contained'
            className='book'
            loading={loading.loadingAccept}
            disabled={loading.loadingAccept || loading.loadingDeny}
            onClick={() => {
              onAcceptInfluencer(influencer.influencerId)
              analytics.logEvent('Influencer_Buchen')
            }}>
            {instanceOfUgcContent(influencer) ? strings.campaign_overview_accept_influencer : strings.campaign_overview_book_influencer}
          </LoadingButton>
        </Grid>
        <Grid item xs={horizontalButtons ? 12 : 4}>
          <ButtonBase
            style={{ height: '36px', width: '100%', backgroundColor: 'white', borderRadius: '10px', border: '1px solid #a890fe', padding: '10px', margin: 0 }}
            onClick={
              !showDenySteps && openDialog
                ? () => {
                    if (instanceOfUgcContent(influencer)) openDialog(influencer)
                  }
                : () => setDenyScreenStep(DenyScreenStep.second)
            }>
            {horizontalButtons ? (
              <Typography variant='body2' color='primary'>
                {strings.campaign_overview_check_give_feedback}
              </Typography>
            ) : (
              <Box component='img' width='18px' height='18px' src={CloseOrangeIcon} alt='X' />
            )}
          </ButtonBase>
        </Grid>
      </Grid>
    </>
  )

  const generateDenyInfluencerScreen = () => {
    switch (denyScreenStep) {
      case DenyScreenStep.second:
        return generateSecondDenyStep()
      case DenyScreenStep.third:
        return generateThirdDenyStep()
      default:
        return generateGeneralInformation(influencer)
    }
  }

  const changeContent = (up: boolean) => {
    console.log('as')
    if (up) {
      if (selectedContent == content.length - 1) {
        setSelectedContent(0)
      } else {
        setSelectedContent(selectedContent + 1)
      }
    } else {
      if (selectedContent == 0) {
        setSelectedContent(content.length - 1)
      } else {
        setSelectedContent(selectedContent - 1)
      }
    }
  }

  return (
    <>
      {/* <InfluencerDialog
        showModal={showModal}
        campaign={props.campaign}
        influencer={influencer}
        onCloseModal={() => setShowModal(false)}
        onAcceptClick={onAcceptInfluencer}
        onDenyClick={onDenyInfluencer}
        loading={loading}
      /> */}
      <Card
        style={{
          paddingBottom: '0.5em',
          backgroundColor: 'white',
          borderStyle: 'solid',
          borderWidth: '2px',
          borderColor: '#f1f1f1',
          borderRadius: '20px !important',
          width: '250px',
          cursor: 'pointer'
        }}>
        <div className={styles.imageContainer}>
          {content.length > 1 && (
            <>
              <KeyboardArrowLeftIcon style={{ position: 'absolute', left: 4, top: 125, zIndex: 1000 }} onClick={() => changeContent(false)} />
              <KeyboardArrowRightIcon style={{ position: 'absolute', right: 4, top: 125, zIndex: 1000 }} onClick={() => changeContent(true)} />
            </>
          )}
          <CardMedia
            onMouseOver={() => setPlayVideo(true)}
            onMouseOut={() => setPlayVideo(false)}
            controls={playVideo}
            controlsList={rootStore.brandStore.selectedBrand?.isAgency ? undefined : 'nodownload'}
            onContextMenu={e => e.preventDefault()}
            component='video'
            image={content[selectedContent]}
            className={detailsOpen ? styles.influencerImageSmall : styles.influencerImage}
          />
          {detailsOpen && (
            <div className={styles.detailsContainer} onClick={() => setDetailsOpen(false)}>
              <b>{first_name}</b>
              {setSelectedUgcInfluencer && (
                <IconButton onClick={() => setSelectedUgcInfluencer(influencer as Applicant)}>
                  <img src={LightBulb} style={{ cursor: 'pointer' }} alt='?' />
                </IconButton>
              )}
              <IconButton>
                <img src={CloseButton} alt='>' />
              </IconButton>
              <span style={{ fontSize: 'medium' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <b>{strings.influencer_dialog_birth_place}</b>
                      </Grid>
                      <Grid item xs={6} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {city}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <b>{strings.influencer_dialog_country}</b>
                      </Grid>
                      <Grid item xs={6}>
                        <ReactCountryFlag countryCode={country ?? 'AT'} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <b>{strings.influencer_dialog_age}</b>
                      </Grid>
                      <Grid item xs={6}>
                        {dateToAge(birth_date)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </span>
            </div>
          )}
        </div>
        {generateDenyInfluencerScreen()}
      </Card>
    </>
  )
}

export default InfluencerCard
