/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx'
import agent from '../agent'
import { RootStore } from './rootStore'
import { User, UpdateUser } from '../requestModels'
import { createBasicPersitence } from '../reusableUtils/Helpers'

class UserStore {
  rootStore: RootStore
  authenticatedUser?: User

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  async getEnabledAnalytics() {
    await this.getUser()
    return this.authenticatedUser ? this.authenticatedUser.enabledAnalytics : false
  }

  async getUser() {
    this.authenticatedUser = await agent.Users.getInformation({})
    console.log(this.authenticatedUser)
  }

  async updateUser(newUser: UpdateUser) {
    await agent.Users.save(newUser)
    await this.getUser()
  }

  hideInformation() {
    return agent.Users.hideInformation({})
  }

  async optOutAnalytics() {
    await agent.Users.optOutAnalytics({})
    if (this.authenticatedUser) {
      this.authenticatedUser.enabledAnalytics = !this.authenticatedUser.enabledAnalytics
    }
  }

  private hideForeverKey = 'creatorsIntroHideForever'
  private creatorsIntroKey = 'creatorsIntro'
  private creatorsIntroHideForeverKey = this.hideForeverKey

  showCreatorsIntro(campaignId: string) {
    const map: Record<string, boolean> = JSON.parse(window.localStorage.getItem(this.creatorsIntroKey) || '{}')
    return !map[this.creatorsIntroHideForeverKey] && !map[campaignId]
  }
  hideCreatorsIntro(campaignId?: string) {
    const map: Record<string, boolean> = JSON.parse(window.localStorage.getItem(this.creatorsIntroKey) || '{}')
    map[campaignId || this.creatorsIntroHideForeverKey] = true
    window.localStorage.setItem(this.creatorsIntroKey, JSON.stringify(map))
  }
  clearCreatorsIntro() {
    window.localStorage.removeItem(this.creatorsIntroKey)
  }

  private upgradeVideoMap = createBasicPersitence<Record<string, boolean>>('upgradeVideo')

  showUpgradeVideo(campaignId: string) {
    const map = this.upgradeVideoMap.get()
    return !map[this.hideForeverKey] && !map[campaignId]
  }
  hideUpgradeVideo(campaignId = this.hideForeverKey) {
    this.upgradeVideoMap.set(map => (map[campaignId] = true))
  }
  clearUpgradeVideo() {
    this.upgradeVideoMap.set({})
  }

  giveFeedback(feedback: string) {
    return agent.Users.giveFeedback({ feedback })
  }
}

export default UserStore
