import { Grid, TextField } from '@mui/material'
import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
// eslint-disable-next-line import/no-cycle
import { analytics } from '../..'
import rootStore from '../../stores/rootStore'
import styles from '../../style/settings.module.css'
import LoaderButton from '../common/layout/LoaderButton'

const passwordRegex = RegExp(/^((?=.*\d)(?=.*[A-Z]).{8,})$/)

type State = { oldPassword: string; newPassword: string; formErrors: { oldPassword: string; newPassword: string }; passwordError: string; isUpdating: boolean }
class NewPassword extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props)
    this.state = { oldPassword: '', newPassword: '', formErrors: { oldPassword: '', newPassword: '' }, passwordError: '', isUpdating: false }
  }

  validateForm = () => this.state.oldPassword.replace(/\s/g, '').length > 0 && this.state.newPassword.replace(/\s/g, '').length > 0

  onChangePasswordClick = () => {
    const { oldPassword, newPassword } = this.state

    this.setState({ isUpdating: true })

    rootStore.authStore
      .changePassword(oldPassword, newPassword)
      .then(() => this.props.history.push('/settings'))
      .catch(err => {
        if (err.code === 'NotAuthorizedException') {
          this.setState({ passwordError: 'Falsches Passwort' })
          alert('Fehler beim Speichern')
        }
        if (err.code === 'InvalidPasswordException') {
          this.setState({ passwordError: 'Falsches Passwort' })
          alert('Fehler beim Speichern')
        }
        if (err.code === 'LimitExceededException') {
          this.setState({ passwordError: 'Zu viele Eingaben' })
          alert('Zu viele Versuche. Bitte später erneut probieren.')
        }
        if (err.code === 'InvalidParameterException') {
          this.setState({ passwordError: 'Falsches Passwort' })
          alert('Fehler beim Speichern')
        }
      })
      .finally(() => this.setState({ isUpdating: false }))
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const { name, value } = event.target
    const formErrors = { ...this.state.formErrors }

    switch (name) {
      case 'oldPassword':
        formErrors.oldPassword = passwordRegex.test(value) ? '' : 'Es sind min. 8 Zeichen, Zahlen und Großbuchstaben erforderlich'
        break
      case 'newPassword':
        formErrors.newPassword = passwordRegex.test(value) ? '' : 'Es sind min. 8 Zeichen, Zahlen und Großbuchstaben erforderlich'
        break
      default:
        break
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.setState({ formErrors, [name]: value })
  }

  render() {
    const { oldPassword, newPassword, formErrors, isUpdating } = this.state

    return (
      <Grid container wrap='nowrap'>
        <Grid item xs={12} className={styles.container}>
          <h1>Passwort ändern</h1>
          <Grid container alignItems='center' style={{ height: '100%' }}>
            <Grid item xs={12} className={styles.form}>
              <TextField
                className={styles.textField}
                label='Altes Passwort'
                name='oldPassword'
                variant='standard'
                value={oldPassword}
                onChange={this.handleChange}
                fullWidth
                error={formErrors.oldPassword !== ''}
                helperText={formErrors.oldPassword}
              />
              <TextField
                className={styles.textField}
                label='Neues Password'
                name='newPassword'
                variant='standard'
                value={newPassword}
                onChange={this.handleChange}
                fullWidth
                error={formErrors.newPassword !== ''}
                helperText={formErrors.newPassword}
              />
              <Grid item xs={12} className={styles.buttonContainer} style={{ textAlign: 'center' }}>
                <LoaderButton
                  onClick={() => {
                    this.onChangePasswordClick()
                    analytics.logEvent('NewPassword_Speichern')
                  }}
                  disabled={!this.validateForm() || formErrors.newPassword !== '' || formErrors.oldPassword !== ''}
                  isLoading={isUpdating}
                  text='Änderungen speichern'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default NewPassword
