/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { mock } from './Helpers'
import useInterval from './useInterval'

const localStorage = window.localStorage
const replacer: (key: string, value: any) => any = (key, value) => {
  if (typeof value == 'string' && value?.startsWith('data:')) return undefined
  return value
}
const areNotEqual = (val1: any, val2: any) => {
  const j1 = JSON.stringify(val1, replacer)
  const j2 = JSON.stringify(val2, replacer)
  return j1 != j2
}
class Persist<T extends {}> {
  constructor(id: string) {
    this.storageKey = id
    const name = id.split('_')[0]
    const keys = () =>
      [...new Array(localStorage.length)].compactMap((_, i) => {
        const key = localStorage.key(i)
        return key?.startsWith(name) ? key : undefined
      })
    const max = 20
    if (keys().length > max) {
      const diff = keys().length - max
      const oldKeys = keys()
        .map(k => ({ key: k, date: (JSON.parse(localStorage.getItem(k) || 'null') ?? {}).date }))
        .ascendingSorted(({ date }) => date)
        .slice(0, diff)
      oldKeys.forEach(({ key }) => localStorage.removeItem(key))
    }
    mock({
      removeAllForms() {
        const length = keys()
        for (const key of keys()) localStorage.removeItem(key)
        return `Removed ${length}`
      },
      getAllForms() {
        return keys().map(key => JSON.parse(localStorage.getItem(key) || 'null') ?? {})
      },
      getCurrentForm: () => {
        return this.current ?? {}
      }
    })
  }

  readonly storageKey: string
  get current(): T | undefined {
    return JSON.parse(localStorage.getItem(this.storageKey) || 'null') ?? undefined
  }
  set current(v) {
    if (v) {
      if (this.isDifferent(v)) {
        const json = JSON.stringify(v, replacer)
        localStorage.setItem(this.storageKey, json)
      }
    } else {
      localStorage.removeItem(this.storageKey)
    }
  }

  isDifferent(v: any) {
    return areNotEqual(v, this.current)
  }
}

const usePersistedForm = <T extends FieldValues>({ getValues, setValue }: UseFormReturn<T, any>, id: string) => {
  const [showPersistDialog, setShowPersistDialog] = useState<object>()
  const { current: persist } = useRef(new Persist<object>(id))
  const startedSaving = useRef(false)
  mock({
    openPersitenceDialog() {
      setShowPersistDialog({} as any)
    }
  })
  useInterval(500, () => {
    if (!startedSaving.current || showPersistDialog) return
    persist.current = getValues() as any
  })
  const compareWithPersistenceAndAsk = () => {
    startedSaving.current = true
    if (persist.isDifferent(getValues())) {
      setShowPersistDialog(persist.current)
    }
  }
  return {
    dialogMethods: {
      deletePersistence: () => {
        persist.current = undefined
        setShowPersistDialog(undefined)
      },
      setDataFromPersistence: () => {
        Object.entries(showPersistDialog ?? {}).forEach(([name, value]) => setValue(name as any, value as any))
        persist.current = showPersistDialog as any
        setShowPersistDialog(undefined)
      },
      showPersistDialog: !!showPersistDialog,
      closePersistDialog: () => {
        setShowPersistDialog(undefined)
      }
    },
    compareWithPersistenceAndAsk
  }
}

export default usePersistedForm
