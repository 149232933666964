import { Card, CardMedia, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import OfflineIcon from '../../assets/icons/ic_offline-status.svg'
import OnlineIcon from '../../assets/icons/ic_online-status.svg'
import PendingIcon from '../../assets/icons/ic_pending-status.svg'
import { useStrings } from '../../assets/localization/strings'
import { Campaign } from '../../stores/campaignStore'
import '../../style/campaign-card.css'
import { color } from '../../theme'
import CampaignDetailsMenu from '../common/CampaignDetailsMenu'
import { VideoType } from '../../models/GeneralTypes'

export const StatusIcon = ({ campaign: { isVerified, isActive } }: { campaign: Campaign }) => {
  if (!isVerified) return <img src={PendingIcon} alt='pending' />

  if (!isActive) return <img src={OfflineIcon} alt='offline' />

  return <img src={OnlineIcon} alt='onlineicon' />
}

export const StatusLabel = ({ campaign: { isVerified, isActive } }: { campaign: Campaign }) => {
  const strings = useStrings()

  if (!isVerified) return <span>{strings.dashboard_campaign_not_verified}</span>

  if (!isActive) return <span>{strings.dashboard_campaign_offline}</span>

  return <span>{strings.dashboard_campaign_online}</span>
}

const CampaignView = observer(({ campaign }: { campaign: Campaign }) => {
  const history = useHistory()
  const strings = useStrings()

  const onCampaignClick = async (brandId: string, campaignId: string) => history.push(`/content/${brandId}/${campaignId}`)

  const { title, campaignId, brandId, campaignImage, accepted, applied, postedContent, videoType } = campaign

  if (!campaignId || !brandId) return <></>

  const applicants = (
    <div>
      <b>{applied ? applied.toLocaleString('de-DE') : 0}</b> {strings.dashboard_campaign_applicants}
    </div>
  )

  const chipString = () => {
    switch (videoType) {
      case VideoType.ad:
        return strings.campaign_creation_video_type_ad_title
      case VideoType.premium:
        return strings.campaign_creation_video_type_premium_title.split(' ')[0] + ' ' + strings.campaign_creation_video_type_premium_title.split(' ')[1]
      default:
        return ''
    }
  }

  return (
    <Grid item xs={12} lg={6}>
      <Grid container>
        <Card className='campaignCard' onClick={() => onCampaignClick(brandId, campaignId)}>
          <div className='cardPadding'>
            <Grid className='cardContainer' container>
              <Grid container>
                <Grid container item xs={11} alignItems='center'>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography variant='h4' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        {campaign?.productInfo?.name ?? title}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container alignItems='right' sx={{ height: '100%' }}>
                        <p
                          style={{
                            padding: '2px 4px',
                            backgroundColor: color.stylinkPurple,
                            color: '#FFF',
                            borderRadius: '4px',
                            height: '16px',
                            marginLeft: '1em',
                            fontSize: '10px',
                            lineHeight: '12px',
                            letterSpacing: '1.5px',
                            fontWeight: '700',
                            marginBottom: 0
                          }}>
                          {chipString()}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <CampaignDetailsMenu campaign={campaign} />
                </Grid>
              </Grid>
              <div>
                <Grid container alignItems='center'>
                  <Grid item className='campaignImageContainer'>
                    <CardMedia className='campaignImage' image={campaignImage} />
                  </Grid>

                  <Grid item className='campaignCardMiddle'>
                    <div className='CardInfo'>
                      <div>
                        <b>{postedContent ? postedContent.toLocaleString('de-DE') : 0}</b> {strings.dashboard_campaign_finished}
                      </div>
                      <div>
                        <b>{accepted.toLocaleString('de-DE') || 0}</b> {strings.dashboard_campaign_participants}
                      </div>
                      {applicants}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container className='campaignStatus'>
                  <Grid>
                    <StatusIcon campaign={campaign} />
                  </Grid>
                  <div className='campaignCardStatus'>
                    <StatusLabel campaign={campaign} />
                  </div>
                </Grid>
              </div>
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  )
})

export default CampaignView
