import { ThemeProvider } from '@mui/material/styles'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { STRIPE_KEY } from './api-config'
import './App.css'
import { Strings, StringsContext } from './assets/localization/strings'
import Auth, { AuthPage } from './components/auth/Auth'
import BrandSettingsWrap from './components/brandSettings/BrandSettingsWrap'
import TodoPopup from './components/common/TodoPopup'
import { MockDialog, MockStoreContext, useMock } from './components/common/MockView'
import ContentCampaignOverview from './components/contentCampaignOverview/ContentCampaignOverview'
import BrandForm from './components/createBrand/BrandForm'
import UGCCampaignForm from './components/createUgcCampaign/UGCCampaignForm'
import CampaignDashboard from './components/dashboard/CampaignDashboard'
import NewPassword from './components/settings/NewPassword'
import Settings from './components/settings/Settings'
import { mock } from './reusableUtils/Helpers'
import BrandRoute from './route/BrandRoute'
import PrivateRoute from './route/PrivateRoute'
import PublicRoute from './route/PublicRoute'
import { theme } from './theme'

const stripePromise = loadStripe(STRIPE_KEY)

export const App = observer(() => {
  const mockObj = useMock()
  const [language, setLanguage] = useState(window.navigator.language.includes('en') ? 'en' : 'de')
  const strings = useMemo(() => Strings(language), [language])
  mock({
    english: () => setLanguage('en'),
    german: () => setLanguage('de')
  })
  useEffect(() => {
    console.log(language)
  }, [language])
  return (
    <StringsContext.Provider value={{ strings, language, setLanguage }}>
      <MockStoreContext.Provider value={mockObj.value}>
        <ThemeProvider theme={theme}>
          <MockDialog {...mockObj} />
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Router>
              <div className='App'>
                <div>
                  {' '}
                  {/* Spacing für Header und Sidebar */}
                  <Elements stripe={stripePromise}>
                    <Switch>
                      <BrandRoute exact path='/:brandId/settings/:site' component={BrandSettingsWrap} />
                      {/* "/dashboard" */}
                      <BrandRoute path='/:brandId/dashboard' exact component={CampaignDashboard} />
                      {/* "/CampaignOverview" */}
                      <BrandRoute path='/:brandId/createContentCampaign/:couponCode?' exact component={UGCCampaignForm} showSidebar={false} />
                      <BrandRoute path='/content/:brandId/:campaignId/:step?' exact component={ContentCampaignOverview} />
                      <BrandRoute
                        exact
                        path='/form/:brandId/:campaignId/:type' // should be view, update or duplicate
                        component={UGCCampaignForm}
                        showSidebar={false}
                      />
                      <BrandRoute exact path='/createBrand' component={BrandForm} showSidebar={false} />
                      <PrivateRoute exact path='/settings' component={Settings} />
                      {/* not tracked */}
                      <PrivateRoute exact path='/newPassword' component={NewPassword} />
                      <PublicRoute exact path='/signup' component={() => <Auth page={AuthPage.signup} />} />
                      <PublicRoute exact path='/login' component={() => <Auth page={AuthPage.login} />} />
                      <PublicRoute exact path='/forgotPassword' component={() => <Auth page={AuthPage.forgotPassword} />} />
                      <PrivateRoute exact path='/onboarding' component={() => <Auth page={AuthPage.onboarding} />} />
                      <PrivateRoute exact path='/platformMode' component={() => <Auth page={AuthPage.platformMode} />} />

                      <BrandRoute exact path='/' component={CampaignDashboard} />
                      <Route render={() => <Redirect to='/' />} />
                    </Switch>
                  </Elements>
                </div>
                <TodoPopup />
              </div>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </MockStoreContext.Provider>
    </StringsContext.Provider>
  )
})
