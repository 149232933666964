import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import im_upgrade_video from '../../../assets/illustrations/im_upgrade_video.png'
import { useStrings } from '../../../assets/localization/strings'
import { UgcContentFinished } from '../../../requestModels'
import rootStore from '../../../stores/rootStore'
import { MockView } from '../../common/MockView'
import { getEditedLinks } from './ContentView'

export const UpgradeVideoDialog = ({ campaignId, content, afterClose }: { campaignId: string; content: UgcContentFinished[]; afterClose(): void }) => {
  const shouldShow = content.some(inf => getEditedLinks(inf).canUpgrade) && rootStore.userStore.showUpgradeVideo(campaignId)
  const strings = useStrings()
  const [open, setOpen] = useState(shouldShow)
  const boxRef = useRef<HTMLElement | null>(null)
  const picOffset = 15
  const close = () => {
    setOpen(false)
    rootStore.userStore.hideUpgradeVideo(campaignId)
  }
  const onClick = () => {
    close()
    afterClose()
  }
  return (
    <>
      <MockView name='Upgrade Dialog'>
        <Stack direction='row'>
          <Button onClick={() => rootStore.userStore.clearUpgradeVideo()}>Clear Persitence</Button>
          <Button onClick={() => setOpen(true)}>Open</Button>
          <Typography>{`Has not shown: ${rootStore.userStore.showUpgradeVideo(campaignId)}`}</Typography>
        </Stack>
      </MockView>
      <Dialog open={open} onClose={close} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box className='zstack' width='100%'>
            <Box height='100%' width='inherit' sx={{ backgroundColor: 'white', borderRadius: 1, mt: picOffset }} />
            <IconButton onClick={close} sx={{ ml: 'auto', mt: 1 }}>
              <CloseIcon />
            </IconButton>
            <img src={im_upgrade_video} style={{ zIndex: 2 }} width='150px' height='150px' />
          </Box>
          <Box ref={boxRef} display='flex' flexDirection='column' alignItems='center' sx={{ backgroundColor: 'white', borderRadius: 1, p: 2 }}>
            <Typography variant='h5'>{strings.upgrade_video_tutorial_title}</Typography>
            <Typography component='div' mt={2} textAlign='center' maxWidth='400px'>
              {strings.upgrade_video_tutorial_body}
            </Typography>
            <Button variant='contained' onClick={onClick} sx={{ mt: 4 }}>
              {strings.upgrade_video_tutorial_button}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
