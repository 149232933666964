export enum ContentCampaignLocale {
  DELIVERY = 'delivery',
  MONEY = 'MONEY'
}

export { ContentCampaignLocale as Locale }

export enum ShippingService {
  AT_POST = 'Österreichische Post',
  DE_POST = 'Deutsche Post',
  DHL = 'DHL',
  DPD = 'DPD',
  GLS = 'GLS',
  UPS = 'UPS',
  OTHER = 'Andere'
}

export const ShippingServicesArray = [
  ShippingService.AT_POST,
  ShippingService.DE_POST,
  ShippingService.DHL,
  ShippingService.DPD,
  ShippingService.GLS,
  ShippingService.UPS,
  ShippingService.OTHER
]

export enum VideoType {
  ad = 'ad',
  premium = 'premium'
}

export enum VideoFormat {
  square = 'square',
  horizontal = 'wide',
  vertical = 'vertical',
  fullscreen = 'fullscreen'
}

export enum VideoFeature {
  small = 'small',
  medium = 'medium',
  up_small_medium = 'up_small_medium'
}

export enum VideoDuration {
  Sec15 = 15,
  Sec30 = 30,
  Sec60 = 60
}

export enum Service {
  selfService = 'selfService',
  nanoService = 'nanoService'
}

export enum ProductType {
  physical = 'physical',
  digital = 'digital'
}

export enum StylinkGender {
  MALE = 'm',
  FEMALE = 'f',
  DIVERSE = 'd'
}

export enum CampaignGender {
  MALE = 'm',
  FEMALE = 'f',
  DIVERSE = 'd',
  ANY = 'a'
}

export enum AgeOptions {
  age1 = '16-18',
  age2 = '18-30',
  age3 = '30+'
}

export enum Role {
  Admin = 'Admin',
  Marketer = 'Marketer',
  Analyst = 'Analyst',
  Accountant = 'Accountant'
}

export enum PaymentType {
  Creditcard = 'creditcard',
  Sepa = 'sepa'
}

export enum TodoType {
  awaitFeedback = 'awaitFeedback',
  seeFeedback = 'seeFeedback',
  awaitContentFeedback = 'awaitContentFeedback',
  awaitDelivery = 'awaitDelivery',
  uploadContent = 'uploadContent'
}

export enum StatusInfo {
  Applied = 'applied',
  Accepted = 'accepted',
  Finished = 'finished',
  Rejected = 'rejected',
  Reported = 'reported', // creator was reported by an admin
  // Means that admin needs to verify whether the user should be accepted
  /** @deprecated */
  ToBeVerified = 'to_be_verified'
}
