import ErrorIcon from '@mui/icons-material/Error'
import { Box, Button, CircularProgress, Dialog, IconButton, Link, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import DownloadContentIcon from '../../../assets/icons/ic_download_content.svg'
import ThreeStarsIcon from '../../../assets/icons/ic_upgrade_package.svg'
import { useStrings } from '../../../assets/localization/strings'
import { VideoFeature } from '../../../models/GeneralTypes'
import { UgcContentFinished } from '../../../requestModels'
import { usePricing } from '../../../reusableUtils/pricing'
import rootStore from '../../../stores/rootStore'
import PaymentModal from '../../common/PaymentModal'
import { Player, PlayerBottomText } from '../../common/Player'
import ReactHookFormRadioCardGroupFullImage from '../../common/form/ReactHookFormRadioCardGroupFullImage'
import { getVideoFeatureOptions } from '../../createUgcCampaign/CampaignAdditionalFeatures'
import { ContentCampaignOverviewContext } from '../ContentCampaignOverview'

type IVideoFeatureForm = { videoFeature: VideoFeature | undefined }

export const getEditedLinks = (influencer: UgcContentFinished) => {
  const { rawContent, editedContent, videoFeature, needsContentToBeEdited } = influencer

  return {
    rawContent,
    editedContent,
    contentToDisplay: editedContent || rawContent,
    canUpgrade: !videoFeature || videoFeature == VideoFeature.small, // in this case he can upgrade
    beingEdited: needsContentToBeEdited
  }
}

export const upgradeVideoButtonClass = 'upgrade-button'
type Props = { pricingObj: ReturnType<typeof usePricing>; influencer: UgcContentFinished }
const ContentView = observer(({ pricingObj: { pricing, getPricing }, influencer }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const { campaign, reload } = useContext(ContentCampaignOverviewContext)
  const { rawContent, editedContent, contentToDisplay, canUpgrade, beingEdited } = getEditedLinks(influencer)
  const strings = useStrings()
  const { enqueueSnackbar } = useSnackbar()

  const methods = useForm<IVideoFeatureForm>({
    defaultValues: {
      videoFeature: undefined
    }
  })
  const { watch } = methods

  const upgrade = async (coupon: string) => {
    const videoFeature = watch('videoFeature')
    if (!videoFeature) {
      alert('No Video Feature selected')
      return
    }
    await rootStore.campaignStore.upgradeVideo({
      brandId: campaign.brandId,
      campaignId: campaign.campaignId,
      influencerId: influencer.influencerId,
      videoFeature,
      coupon
    })
    setShowPayment(false)
    reload()
    enqueueSnackbar(strings.campaign_overview_content_upgraded_message, { variant: 'success' })
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const renderVideoFeatures = () => {
    if (pricing == null) return <CircularProgress />
    if (pricing instanceof Error)
      return (
        <IconButton onClick={getPricing}>
          <ErrorIcon />
        </IconButton>
      )
    return (
      <ReactHookFormRadioCardGroupFullImage name='videoFeature' options={getVideoFeatureOptions(strings, pricing, [influencer.videoFeature]).filterNotNull()} required={true} />
    )
  }

  const renderUpgradeChip = () =>
    (!influencer.videoFeature || influencer.videoFeature == VideoFeature.small) && (
      <Box
        component='button'
        sx={{ p: '0px 6px', backgroundColor: theme => theme.palette.primary.main, borderRadius: '20px' }}
        onClick={() => {
          setShowUpgrade(true)
        }}>
        <Stack direction='row' alignItems='center' className={upgradeVideoButtonClass}>
          <img src={ThreeStarsIcon} style={{ width: '12px', marginRight: '6px' }} />
          <Typography variant='caption' fontWeight='600' color='white'>
            {strings.campaign_overview_content_upgrade_label}
          </Typography>
        </Stack>
      </Box>
    )
  const renderBeingEditedChip = () =>
    beingEdited && (
      <Box sx={{ ml: '7px', mt: '7px', mr: 'auto', p: '6px', backgroundColor: theme => theme.palette.primary.main, borderRadius: 1 }}>
        <Typography variant='caption' fontWeight='800' color='white'>
          {strings.campaign_overview_content_being_edited}
        </Typography>
      </Box>
    )
  const renderDownloadChip = () => (
    <Box>
      {editedContent && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
          <MenuItem>
            <Link href={editedContent.link} target='_blank' underline='none' color='black'>
              {strings.campaign_overview_content_download}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href={rawContent.link} target='_blank' underline='none' color='black'>
              {strings.campaign_overview_content_raw_download}
            </Link>
          </MenuItem>
        </Menu>
      )}
      <Box component='a' {...(editedContent ? { onClick: handleClick } : { href: rawContent.link, target: '_blank' })}>
        <Box component='img' src={DownloadContentIcon} alt='bezahlt' width='15px' height='20px' color={theme => theme.palette.primary.main} />
      </Box>
    </Box>
  )
  const renderUpgradeDialog = () => (
    <Dialog open={showUpgrade} onClose={() => setShowUpgrade(false)} fullWidth maxWidth='md'>
      <Box p={6}>
        {renderVideoFeatures()}
        {watch('videoFeature') && (
          <Button
            fullWidth
            variant='contained'
            sx={{ ml: 'auto', mt: '24px' }}
            onClick={() => {
              setShowUpgrade(false)
              setShowPayment(true)
            }}>
            {strings.next}
          </Button>
        )}
      </Box>
    </Dialog>
  )

  return (
    <FormProvider {...methods}>
      <Box height='100%' p={1}>
        <PaymentModal open={showPayment} handleClose={() => setShowPayment(false)} callback={upgrade} videoFeature={watch('videoFeature')} numberOfVideos={1} />
        {renderUpgradeDialog()}
        <Player src={contentToDisplay.link}>
          <>
            <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 1, mr: 2, ml: 1 }}>
              {canUpgrade ? renderUpgradeChip() : renderBeingEditedChip()}
              {renderDownloadChip()}
            </Stack>
            <PlayerBottomText icon={influencer?.image} name={influencer.first_name} />
          </>
        </Player>
      </Box>
    </FormProvider>
  )
})

export default ContentView
