/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldArrayPath, FieldValues, UseFieldArrayProps, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form'

export const useCustomFieldArray = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id'
>({
  name
}: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName>) => {
  type T = UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>
  const { getValues, setValue, control } = useFormContext()
  const fields = useWatch({ control, name })
  return {
    append: el => {
      const original: any[] = getValues(name)
      original.push(el)
      setValue(name, original as any)
    },
    remove: is => {
      const original: any[] = getValues(name)
      for (const i of typeof is == 'number' ? [is] : is ?? []) original.splice(i, 1)
      setValue(name, original as any)
    },
    update: (i, v) => {
      const original: any[] = getValues(name)
      original[i] = v
      setValue(name, original as any)
    },
    fields
  } as Pick<T, 'append' | 'fields' | 'remove' | 'update'>
}
