import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

import ErrorIcon from '@mui/icons-material/Error'
import { Box, Button, Card, CardContent, CircularProgress, Divider, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { UseFormReturn, useWatch } from 'react-hook-form'
import agent from '../../agent'
import { useStrings } from '../../assets/localization/strings'
import { Service, VideoDuration, VideoFeature, VideoType } from '../../models/GeneralTypes'
import { delay, numberToEuro, State, useCancellablePromise } from '../../reusableUtils/Helpers'
import rootStore from '../../stores/rootStore'
import { MockView } from '../common/MockView'
import { CouponCodeView } from './CouponView'
import type { IUGCCampaign } from './UGCCampaignForm'
import { ICompanyForm } from '../../types/paymentModal'

export type Invoice = Awaited<ReturnType<typeof agent.Brands.previewInvoice>>

type Data = {
  numberOfVideos?: number
  videoType?: VideoType
  videoFeature?: VideoFeature
  videoDuration?: VideoDuration
  product: Service | undefined
  brand?: object
  hooks?: string[]
  ctas?: string[]
}
type PricingContext = State<'invoice', Invoice | Error | undefined> & {
  ignoreLoad: React.MutableRefObject<boolean>
  invoiceToUse: React.MutableRefObject<Invoice | undefined>
  initialCouponRef: React.MutableRefObject<string | undefined>
  data: Data
  load: (delayDuration?: number, data?: ICompanyForm) => Promise<void>
} & State<'couponCode', string>

export const usePricingForm = (data: Data): PricingContext => {
  const [invoice, setInvoice] = useState<Invoice | Error>()
  const [ignoreLoad, invoiceToUse] = [useRef(false), useRef<Invoice>()]
  const initialCouponRef = useRef(Object.fromEntries(new URLSearchParams(window.location.search).entries()).couponCode as string | undefined)
  const [couponCode, setCouponCode] = useState('')
  const { product, numberOfVideos, videoDuration, videoFeature, videoType, brand, hooks = [], ctas = [] } = data

  const launch = useCancellablePromise()
  const load = (delayDuration = 1000, data?: ICompanyForm) =>
    launch(async ensureIsActive => {
      setInvoice(undefined)
      await delay(delayDuration)
      ensureIsActive()
      const paymentData = data || brand
      try {
        const result = await rootStore.brandStore.previewInvoice(
          numberOfVideos ?? 1,
          videoType,
          videoFeature,
          videoDuration,
          initialCouponRef.current || couponCode,
          product,
          paymentData,
          hooks,
          ctas
        )
        ensureIsActive()
        invoiceToUse.current = result
        setInvoice(result)
        if (initialCouponRef.current) setCouponCode(initialCouponRef.current)
      } catch (e) {
        ensureIsActive()
        setInvoice(e)
        if (!invoiceToUse.current && e['status'] == 422) setCouponCode('')
        if (initialCouponRef.current) load(0)
      }
      initialCouponRef.current = undefined
    })

  useEffect(() => {
    if (ignoreLoad?.current && invoiceToUse.current) {
      ignoreLoad.current = false
      return
    }
    load()
  }, [JSON.stringify(brand), numberOfVideos, videoDuration, videoFeature, videoType, couponCode, product, JSON.stringify(hooks), JSON.stringify(ctas)])

  return { invoice, setInvoice, ignoreLoad, invoiceToUse, initialCouponRef, data, couponCode, setCouponCode, load }
}
export const usePricingFormByContext = (control: UseFormReturn<IUGCCampaign>['control'], brand?: object): PricingContext => {
  const numberOfVideos = +useWatch({ control, name: 'numberOfVideos' }) || 0 // sometimes it turns to string when empty
  const videoDuration = useWatch({ control, name: 'videoDuration' }) as VideoDuration
  const videoFeature = useWatch({ control, name: 'videoFeature' })
  const videoType = useWatch({ control, name: 'videoType' })
  const service = useWatch({ control, name: 'service' })
  const numberOfHooks = useWatch({ control, name: 'numberOfHooks' })
  const numberOfCtas = useWatch({ control, name: 'numberOfCtas' })
  return usePricingForm({
    numberOfVideos,
    videoDuration,
    videoFeature,
    videoType,
    product: service,
    brand,
    hooks: Array.from({ length: numberOfHooks }, (_, index) => (index + 1).toString()),
    ctas: Array.from({ length: numberOfCtas }, (_, index) => (index + 1).toString())
  })
}

export const PricingFormContext = createContext(undefined as unknown as PricingContext)

interface Props {
  mobile?: boolean
  title?: string
  body?: string
  mt?: number
  formValues?: ICompanyForm
  isInvoiceLoading?: boolean
  onChangeLoadingInvoice?: (value: boolean) => void
}

export const PricingBasedOnInvoice = (props: Props) => {
  const strings = useStrings()
  const {
    invoiceToUse,
    invoice,
    setInvoice,
    data: { numberOfVideos, hooks, ctas },
    couponCode,
    setCouponCode: setCoupon,
    load
  } = useContext(PricingFormContext)
  const { title = strings.preview_invoice_title, body = strings.preview_invoice_body, mt, mobile = false, formValues, isInvoiceLoading, onChangeLoadingInvoice } = props
  const couponFocused = useRef(false)

  useEffect(() => {
    if (formValues) load(1000, formValues)
  }, [formValues?.vatNumber, formValues?.vatNumberCheck, formValues?.country, formValues?.zip])

  useEffect(() => {
    if (!invoice && !isInvoiceLoading) {
      onChangeLoadingInvoice && onChangeLoadingInvoice(true)
    }
    if (invoice && isInvoiceLoading) {
      onChangeLoadingInvoice && onChangeLoadingInvoice(false)
    }
  }, [invoice, isInvoiceLoading])

  const renderLine = ({ key, label, value, variant = 'body2' }: { key?: string; label: string; value: string; variant?: 'h4' | 'body2' }) => {
    const color = value.startsWith('- ') ? 'orange' : undefined // make discounts orange
    const mb = mobile ? 0.6 : 1
    return (
      <React.Fragment key={key}>
        <Grid item xs={6} mb={mb}>
          <Typography variant={variant} color={color}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6} mb={mb} textAlign='right'>
          <Typography variant={variant} color={color}>
            {value}
          </Typography>
        </Grid>
      </React.Fragment>
    )
  }
  const renderCoupon = (type: 'percent' | 'amount', value: number | null) => {
    if (value == null || value == 0) return null
    return renderLine({ label: strings.preview_invoice_discount, value: `${type == 'amount' ? `- € ${numberToEuro(value)}` : `- ${value}%`}` })
  }
  const renderDivider = () => (
    <Grid item xs={12} mb={1}>
      <Divider />
    </Grid>
  )

  const labels = {
    [VideoFeature.small]: strings.pricing_video_feature_small,
    [VideoFeature.medium]: strings.pricing_video_feature_medium,
    [VideoFeature.up_small_medium]: strings.pricing_video_feature_medium,
    [VideoDuration.Sec15]: strings.pricing_video_duration,
    [VideoDuration.Sec30]: strings.pricing_video_duration,
    [VideoDuration.Sec60]: strings.pricing_video_duration,
    [VideoType.ad]: strings.pricing_video_type,
    [VideoType.premium]: strings.pricing_video_type,
    [Service.selfService]: strings.pricing_self_service,
    [Service.nanoService]: strings.pricing_nano_service,
    ['hook']: strings.pricing_hooks((hooks?.length ?? 1) - 1),
    ['cta']: strings.pricing_ctas((ctas?.length ?? 1) - 1)
  }

  const inv = invoiceToUse.current
  const shouldRenderTax = inv?.tax_exempt != 'exempt'
  return (
    <>
      <MockView name='Pricing'>
        <Stack direction='row'>
          <Button onClick={() => setInvoice(new Error())}>Set Error</Button>
          <Button onClick={() => setInvoice(undefined)}>Set Loading</Button>
          <Button onClick={() => ((couponFocused.current = true), setInvoice(undefined))}>Set Coupon Loading</Button>
        </Stack>
      </MockView>
      {inv ? (
        <Stack mt={mt}>
          <Box className='zstack'>
            <Card variant='outlined' sx={{ p: mobile ? 0 : 2 }}>
              <CardContent sx={{ p: mobile ? 1 : undefined }}>
                <Grid container>
                  {!mobile && (
                    <>
                      <Grid item xs={12} mb={2}>
                        <Typography variant='h5'>{title}</Typography>
                      </Grid>
                      <Grid item xs={12} mb={2}>
                        <Typography variant='body2'>{body}</Typography>
                      </Grid>
                    </>
                  )}
                  {inv.items.map(item => renderLine({ key: `${item.name}`, label: labels[item.name], value: `€ ${numberToEuro(item.value)}` }))}
                  {numberOfVideos != null && renderLine({ label: strings.preview_invoice_count, value: `${inv.numberOfVideos}x` })}
                  {renderCoupon('amount', inv.amount_off)}
                  {renderCoupon('percent', inv.percent_off)}
                  {renderDivider()}
                  {shouldRenderTax && renderLine({ label: strings.preview_invoice_netto, value: `€ ${numberToEuro(inv.beforeTax ?? 0)}` })}
                  {shouldRenderTax &&
                    renderLine({
                      label: inv.tax_exempt == 'reverse' ? strings.preview_invoice_reverse_charge : strings.preview_invoice_ust_20,
                      value: `€ ${numberToEuro(inv.tax ?? 0)}`
                    })}
                  {shouldRenderTax && renderLine({ label: strings.preview_invoice_brutto, value: `€ ${numberToEuro(inv.total)}`, variant: inv.appliedBalance ? 'body2' : 'h4' })}
                  {!!inv.appliedBalance && renderDivider()}
                  {!!inv.appliedBalance && !!inv.total && renderLine({ label: 'Guthaben', value: `- € ${numberToEuro(inv.appliedBalance)}` })}
                  {!!inv.appliedBalance && renderLine({ label: 'Zu zahlen:', value: `€ ${numberToEuro(inv.amount_due)}`, variant: 'h4' })}
                </Grid>
              </CardContent>
            </Card>
            {((invoice == null && (!couponFocused.current || !couponCode)) || (invoice instanceof Error && (invoice as any)['status'] != 422)) && (
              <>
                {invoiceToUse.current && <Box sx={{ backgroundColor: 'gray', opacity: 0.2 }} width='100%' height='100%' />}
                {invoice == null ? (
                  <CircularProgress />
                ) : (
                  <IconButton onClick={() => load(0)}>
                    <ErrorIcon />
                  </IconButton>
                )}
              </>
            )}
          </Box>
          <Card variant='outlined' sx={{ width: '100%', mt: 2 }}>
            <CardContent>
              <CouponCodeView
                coupon={couponCode}
                setCoupon={setCoupon}
                state={invoice == null || invoice instanceof Error ? invoice : {}}
                load={load}
                couponFocused={couponFocused}
              />
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <Skeleton height='400px' sx={{ width: '400px', maxWidth: '100%', mt }} />
      )}
    </>
  )
}

export const PricingInUGCForm = PricingBasedOnInvoice
