import { Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useStrings } from '../../assets/localization/strings'
import { VideoType } from '../../models/GeneralTypes'
import { UgcContentFinished } from '../../requestModels'
import { State } from '../../reusableUtils/Helpers'
import { ContentCampaignOverviewContext, OverviewStep } from './ContentCampaignOverview'
import TabNavigation from '../common/TabNavigation'

type Props = State<'step', OverviewStep | undefined> & { accepted: number | undefined; content: UgcContentFinished[] | undefined }
const DetailsNavigation = ({ step, setStep, accepted, content }: Props) => {
  const strings = useStrings()
  const { campaign } = useContext(ContentCampaignOverviewContext)
  const applicationFinished = campaign ? campaign.numberOfSlots == accepted : null
  const finished = campaign ? campaign.numberOfSlots * campaign.numberOfVideosPerInfluencer == content?.length : null
  const steps = campaign
    ? [campaign.applied || !applicationFinished ? [OverviewStep.APPLICATIONS] : [], finished ? [] : [OverviewStep.CREATORS, OverviewStep.CHECK], OverviewStep.CONTENT].flat()
    : []

  useEffect(() => {
    if (!steps.length || !step) return

    if (!steps.includes(step)) setStep(steps[0])
  }, [JSON.stringify(steps), step])

  const tabs = steps.map(s => {
    switch (s) {
      case OverviewStep.APPLICATIONS:
        return {
          value: OverviewStep.APPLICATIONS,
          label: strings.campaignOverviewStepApplications
        }
      case OverviewStep.CREATORS:
        return {
          value: OverviewStep.CREATORS,
          label: strings.campaignOverviewStepCreators
        }
      case OverviewStep.CHECK:
        return {
          value: OverviewStep.CHECK,
          label: strings.campaignOverviewStepCheck
        }
      case OverviewStep.CONTENT:
        return {
          value: OverviewStep.CONTENT,
          label: strings.campaignOverviewStepContent
        }
    }
  })

  return (
    <Grid container>
      <Grid item xs={12}>
        <TabNavigation tabs={tabs} selectedTab={step ?? OverviewStep.APPLICATIONS} setTab={id => setStep(id as OverviewStep)} />
      </Grid>
    </Grid>
  )
}

export default DetailsNavigation
