import * as Icons from '@mui/icons-material'
import { Alert, Autocomplete, Button, Checkbox, FormControlLabel, Grid, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import ReactJoyride, { Step } from 'react-joyride'
import { v4 } from 'uuid'
import ContentIcon from '../../../assets/icons/ic_content.svg'
import DeliveryIcon from '../../../assets/icons/ic_delivery.svg'
import LightBulb from '../../../assets/icons/ic_lightBulb.svg'
import PeopleIcon from '../../../assets/icons/ic_people.svg'
import NoParticipants from '../../../assets/illustrations/im_no-participants.png'
import { useStrings } from '../../../assets/localization/strings'
import { ContentCampaignLocale, Locale, ShippingService, StatusInfo, TodoType } from '../../../models/GeneralTypes'
import { CampaignInsightsUgc, ParticipantUgc } from '../../../requestModels'
import { State } from '../../../reusableUtils/Helpers'
import rootStore from '../../../stores/rootStore'
import styles from '../../../style/contentCampaign/creator.module.css'
import { theme } from '../../../theme'
import { MockView } from '../../common/MockView'
import { OverviewStep } from '../ContentCampaignOverview'
import ProductPreferencesDialog from './ProductPreferencesDialog'
import { ShippingButton } from './ShippingButton'
import ShippingDialog from './ShippingDialog'
import UgcParticipantMenu from './UgcParticipantMenu'

type Props = State<'participants', ParticipantUgc[]> &
  State<'campaign', CampaignInsightsUgc> & {
    onSent(influencerId: string, shppingCode: string, shippingService: ShippingService | string): Promise<void>
    removeInfluencer: (influencer: ParticipantUgc) => void
    setStep(newStep: OverviewStep): void
  }

const Creators = observer(({ participants, setParticipants, campaign, setCampaign, onSent, removeInfluencer, setStep }: Props) => {
  const [loadingAddress, setLoadingAddress] = useState<string>()

  const [openShippingDialog, setOpenShippingDialog] = useState(false)
  const [openPreferencesDialog, setOpenPreferencesDialog] = useState(false)

  const [selectedInfluencer, setSelectedInfluencer] = useState(undefined as ParticipantUgc | undefined)

  const [selectedUgcInfluencer, setSelectedUgcInfluencer] = useState(undefined as ParticipantUgc | undefined)
  const strings = useStrings()

  const handleSent = async (influencer: ParticipantUgc, shippingCode: string, shippingService: ShippingService | string) => {
    setLoadingAddress(influencer.influencerId)
    try {
      await onSent(influencer.influencerId, shippingCode, shippingService)
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingAddress(undefined)
      setOpenShippingDialog(false)
      setSelectedInfluencer(undefined)
    }
  }

  const steps: Step[] = [
    {
      target: '.my-first-step',
      content: strings.intro_campaign_overview_creators_send(moment(participants[0]?.nextTodo?.endDate).format('DD.MM.YYYY')),
      disableBeacon: true,
      locale: { next: strings.clear, skip: <p style={{ opacity: 0.5, color: 'gray' }}>{strings.tutorial_do_not_show_again}</p> }
    },
    { target: '.ee', content: 'dummy' }
  ]

  const renderStatus = (participant: ParticipantUgc, isLate: boolean) => {
    const nextTodo = participant.nextTodo
    const endDate = moment(nextTodo?.endDate).format('DD.MM.YYYY')
    // It was deleted
    if (participant.statusInfo == 'reported') {
      return (
        <div className={`${styles.producedTextLabel} ${styles.redBackground}`}>
          {participant.firstUploadDone ? strings.creators_status_deleted_secondupload : strings.creators_status_deleted_firstupload}
        </div>
      )
    } else {
      switch (participant.statusInfo == 'finished' ? null : nextTodo?.type) {
        case TodoType.awaitDelivery:
          return <div className={`${styles.waitingTextLabel} ${isLate ? styles.redBackground : ''}`}>{strings.campaign_overview_creators_send_until(endDate)}</div>
        case TodoType.uploadContent:
          if (participant.needsAdminApproval) {
            return <div className={styles.beingEditedLabel}>{strings.campaign_overview_creators_wait_for_feedback}</div>
          } else {
            return participant.firstUploadDone ? (
              <div className={`${styles.producedTextLabel} ${isLate ? styles.redBackground : ''}`}>{strings.campaign_overview_creators_make_2nd_content(endDate)}</div>
            ) : (
              <div className={`${styles.waitingTextLabel} ${isLate ? styles.redBackground : ''}`}>{strings.campaign_overview_make_content(endDate)}</div>
            )
          }
        case TodoType.awaitContentFeedback:
          return campaign.contentCheck.find(t => t.influencerId == participant.influencerId) ? (
            <div className={`${styles.waitingTextLabel} ${isLate ? styles.redBackground : ''}`}>{strings.campaign_overview_creators_give_feedback(endDate)}</div>
          ) : (
            <div className={styles.beingEditedLabel}>{strings.campaign_overview_creators_wait_for_feedback}</div>
          )
        default:
          if (participant.needsContentToBeEdited) {
            return <div className={styles.beingEditedLabel}>{strings.campaign_overview_creators_being_edited}</div>
          } else {
            return <div className={styles.doneTextLabel}>{strings.campaign_overview_creators_finished}</div>
          }
      }
    }
  }

  const renderTodoStatus = (participant: ParticipantUgc, isLate: boolean) => {
    const renderText = (text: string) => <div className={styles.todoWhite}>{text}</div>
    const renderSuccess = () => renderText(strings.campaign_overview_creators_success)
    const loading = loadingAddress == participant.influencerId
    switch (participant.nextTodo?.type) {
      case TodoType.awaitDelivery:
        return (
          isDelivery && (
            <ShippingButton
              isLate={isLate}
              loading={loading}
              className={isDelivery && participants.indexOf(participant) == 0 ? (steps[0].target as string).slice(1) : undefined}
              onClick={() => {
                if (loading) return
                setSelectedInfluencer(participant)
                setOpenShippingDialog(true)
              }}
            />
          )
        )
      case TodoType.awaitContentFeedback:
        return campaign.contentCheck.find(t => t.influencerId == participant.influencerId) ? (
          <div className={`${styles.todoBlack} ${isLate ? styles.redBackground : ''} go-to-check`} onClick={() => setStep(OverviewStep.CHECK)}>
            {strings.campaign_overview_creators_check_content}
          </div>
        ) : (
          <div className={`${styles.todoWhite}`}>{strings.wait}</div>
        )
      case TodoType.uploadContent:
      case TodoType.seeFeedback:
        return <div className={`${styles.todoWhite} ${isLate ? styles.redBackground : ''}`}>{participant.firstUploadDone ? renderSuccess() : strings.wait}</div>
      default:
        return renderSuccess()
    }
  }

  const isDelivery = (Locale.DELIVERY as string) == (campaign.locale as string)
  const showProductPreferences = !!campaign.productPreferences?.length
  const showAddress = isDelivery

  console.log(participants)

  const renderCreators = () => (
    <Paper style={{ color: '#BDBDBD' }}>
      <Table>
        <TableHead>
          <TableCell component='span' className={styles.tableHeader}>
            {strings.campaign_overview_creators_status}
          </TableCell>
          <TableCell component='span' className={styles.tableHeader}>
            {strings.campaign_overview_creators_creator}
          </TableCell>
          <TableCell component='span' className={styles.tableHeader}>
            {strings.campaign_overview_creators_todo}
          </TableCell>
          {showAddress && (
            <TableCell component='span' className={styles.tableHeader}>
              {strings.campaign_overview_creators_address}
            </TableCell>
          )}
          {showProductPreferences && (
            <TableCell component='span' className={styles.tableHeader}>
              {strings.campaign_overview_creators_product_preferences}
            </TableCell>
          )}
          <TableCell component='span' className={styles.tableHeader}>
            {strings.more}
          </TableCell>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {participants.map(participant => {
            const isLate = participant.statusInfo != 'finished' && Date.now() > (participant.nextTodo?.endDate ?? Infinity)
            return (
              <TableRow key={participant.influencerId}>
                <TableCell>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    {isLate && <Icons.Error className={styles.redText} />}
                    {renderStatus(participant, isLate)}
                  </Stack>
                </TableCell>
                <TableCell>
                  <Grid container alignItems='center'>
                    {participant.image && <img className={styles.influencerPicture} src={participant.image} alt='.' />}
                    <div className={`${isLate ? styles.redText : ''}`}>{`${participant.first_name} ${participant.last_name}`}</div>
                  </Grid>
                </TableCell>
                <TableCell>{renderTodoStatus(participant, isLate)}</TableCell>
                {showAddress && (
                  <TableCell component='span' className={isLate ? styles.redText : ''}>
                    {!!participant.country && <ReactCountryFlag countryCode={participant.country} style={{ paddingRight: '4px' }} />}
                    {participant.address}, {participant.zip} {participant.city}{' '}
                  </TableCell>
                )}
                {showProductPreferences && (
                  <TableCell
                    onClick={() => {
                      setSelectedUgcInfluencer(participant)
                      setOpenPreferencesDialog(true)
                    }}>
                    <img src={LightBulb} style={{ width: '12px', marginLeft: '4.5em', cursor: 'pointer' }} alt='?' />
                  </TableCell>
                )}
                <TableCell>
                  <UgcParticipantMenu influencer={participant} campaign={campaign} removeInfluencer={removeInfluencer} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )

  const renderNoParticipants = () => (
    <Grid container direction='column' justifyContent='center' alignItems='center' className={styles.noParticipantsContainer}>
      <Grid item>
        <img className={styles.noParticipants} src={NoParticipants} alt='no participants' />
      </Grid>
      <Grid item>
        <h2>{strings.campaign_overview_creators_empty_title}</h2>
      </Grid>
      <Grid item>
        <span>{strings.campaign_overview_creators_empty_body}</span>
      </Grid>
    </Grid>
  )

  const renderCard = (i: number, title: string, amount: number, icon: string) => (
    <Grid item xs={12} md={campaign.locale === (Locale.MONEY as any) ? 6 : 4} key={i}>
      <Grid container justifyContent='center' className={styles.cardContainerSmall} height='90%'>
        <span className={styles.iconContainer}>
          <Grid container alignItems='center' justifyContent='center'>
            <img className={styles.icon} src={icon} alt='People' />
            <b>{title}</b>
          </Grid>
        </span>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <span className={styles.amountContainer}>
              <b>{amount}</b>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const renderCards = () => {
    const titles = [strings.campaign_overview_creators_title1, strings.campaign_overview_creators_title2, strings.campaign_overview_creators_title3]
    let creators = 0
    let productReceived = 0
    let contentCreated = 0
    participants.forEach(p => {
      if (p.statusInfo == 'finished') {
        contentCreated++
      }

      if (p.productSent) {
        productReceived++
      }

      if (p.statusInfo != 'reported') creators++
    })
    const amounts = [creators, productReceived, contentCreated]

    const icons = [PeopleIcon, DeliveryIcon, ContentIcon]
    return (
      <Grid container spacing={2}>
        {titles.map((title, index) => {
          if (campaign.locale === (Locale.MONEY as string) && index === 1) return <></>
          return renderCard(index, title, amounts[index], icons[index])
        })}
      </Grid>
    )
  }

  const Mock = useCallback(() => {
    const [type, setType] = useState(TodoType.awaitDelivery)
    const [late, setLate] = useState(false)
    const [secondUpload, setSecondUpload] = useState(false)
    const dummyTodo = () => ({
      campaignInfluencerId: v4(),
      _type: 'ugc' as any,
      contentLinks: [],
      editedContentLinks: [],
      startDate: moment().add(-8, 'd').valueOf(),
      endDate: moment().add(1, 'd').valueOf(),
      impressions: [],
      isCompleted: false,
      isPayed: false,
      oembededLinks: [],
      oldEndDates: [],
      postingComments: [],
      postingLikes: [],
      postingLinks: [],
      reach: [],
      secondUpload: true,
      todoId: v4(),
      type: TodoType.awaitDelivery,
      checkedByStaff: false
    })
    const createTodo: {
      (type: TodoType, late: boolean): void
      (type: TodoType.uploadContent, late: boolean, secondUpload: boolean): void
    } = (type: TodoType, late: boolean, secondUpload?: boolean) =>
      setParticipants(p => {
        const target = {} as unknown as ParticipantUgc
        target.influencerId = v4()
        target.nextTodo = target.nextTodo ?? dummyTodo()
        target.nextTodo.type = type
        if (late) target.nextTodo.endDate = moment().add(-1, 'd').valueOf()
        target.firstUploadDone = !!secondUpload && type == TodoType.uploadContent
        return [...p, target]
      })
    const options = [TodoType.awaitDelivery, TodoType.uploadContent, TodoType.awaitContentFeedback]
    return (
      <MockView name='Creators'>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Autocomplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            sx={{ width: 200 }}
            value={type}
            onChange={(_, newValue) => (newValue ? setType(newValue as TodoType) : null)}
            renderInput={params => <TextField {...params} label='Todo Type' />}
          />
          <FormControlLabel control={<Checkbox value={late} onChange={e => setLate(e.target.checked)} />} label='late' />
          {type == TodoType.uploadContent && (
            <FormControlLabel control={<Checkbox value={secondUpload} onChange={e => setSecondUpload(e.target.checked)} />} label='secondUpload' />
          )}
          <Button onClick={() => createTodo(type as any, late, secondUpload)}>Create Todo</Button>
          <Button
            onClick={() => {
              createTodo(TodoType.awaitDelivery, false)
              createTodo(TodoType.awaitDelivery, true)
              createTodo(TodoType.awaitContentFeedback, false)
              createTodo(TodoType.awaitContentFeedback, true)
              createTodo(TodoType.uploadContent, false, false)
              createTodo(TodoType.uploadContent, true, false)
              createTodo(TodoType.uploadContent, false, true)
              createTodo(TodoType.uploadContent, true, true)
            }}>
            Create all combos
          </Button>
          <Button onClick={() => rootStore.userStore.clearCreatorsIntro()}>Clear Hide Creators Intro</Button>
        </Stack>
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={[Locale.DELIVERY, Locale.MONEY]}
          sx={{ width: 200 }}
          value={campaign.locale as any}
          onChange={(_, newValue) => (newValue ? setCampaign(p => ({ ...p, locale: newValue as any })) : null)}
          renderInput={params => <TextField {...params} label='Locale' />}
        />
      </MockView>
    )
  }, [])

  return (
    <>
      <ReactJoyride
        callback={e => {
          if (e.action != 'skip' && e.action != 'next') return
          rootStore.userStore.hideCreatorsIntro(e.action == 'skip' ? undefined : campaign.campaignId)
        }}
        steps={steps}
        continuous
        hideCloseButton
        run={rootStore.userStore.showCreatorsIntro(campaign.campaignId)}
        scrollToFirstStep
        showSkipButton
        styles={{ options: { zIndex: 10000, primaryColor: theme.palette.primary.main } }}
      />
      <Mock />
      <ShippingDialog
        open={openShippingDialog}
        handleSent={handleSent}
        influencer={selectedInfluencer}
        onClose={() => {
          setOpenShippingDialog(false)
          setSelectedInfluencer(undefined)
        }}
        loading={!!loadingAddress}
      />
      <ProductPreferencesDialog
        open={openPreferencesDialog}
        influencer={selectedUgcInfluencer}
        campaign={campaign}
        onClose={() => {
          setOpenPreferencesDialog(false)
          setSelectedUgcInfluencer(undefined)
        }}
      />
      <Grid container>
        <Grid container justifyContent='space-between' style={{ marginBottom: '16px' }}>
          {(campaign.locale as any) == ContentCampaignLocale.DELIVERY && participants.find(p => p.statusInfo == StatusInfo.Accepted) && (
            <Grid item xs={12} pt={1} pb={1}>
              <Alert severity='info'>{strings.campaign_overview_creators_disclaimer}</Alert>
            </Grid>
          )}
          <Grid item xs={12} pt={1} pb={1}>
            {renderCards()}
          </Grid>
          <Grid item xs={12} pt={1} pb={1}>
            <div>
              <div className={styles.cardContainer}>{participants?.length ? renderCreators() : renderNoParticipants()}</div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
})

export default Creators
