/* eslint-disable import/no-cycle */
import AuthStore from './authStore'
import BrandStore from './brandStore'
import CampaignStore from './campaignStore'
import ShopStore from './shopStore'
import StatusStore from './statusStore'
import UserStore from './userStore'

export class RootStore {
  authStore: AuthStore
  brandStore: BrandStore
  campaignStore: CampaignStore
  shopStore: ShopStore
  statusStore: StatusStore
  userStore: UserStore

  constructor() {
    this.authStore = new AuthStore(this)
    this.brandStore = new BrandStore()
    this.campaignStore = new CampaignStore(this)
    this.shopStore = new ShopStore(this)
    this.statusStore = new StatusStore(this)
    this.userStore = new UserStore(this)
  }
}

export default new RootStore()
