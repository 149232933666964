import { Close, Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import ReactPlayer from 'react-player'
import Add from '../../../assets/icons/ic_add_orange.svg'
import { useStrings } from '../../../assets/localization/strings'
import { UgcContent } from '../../../requestModels'
import rootStore from '../../../stores/rootStore'
import { ContentCampaignOverviewContext } from '../ContentCampaignOverview'

type Props = {
  handleClose(influencerId?: string): void
  open: boolean
  influencer: UgcContent
  campaignId: string
  brandId: string
}

export const DenyDialog = ({ handleClose, open, influencer, campaignId, brandId }: Props) => {
  const strings = useStrings()

  return (
    <Dialog fullWidth maxWidth='md' open={open} onClose={() => handleClose()}>
      <DialogContent sx={{ pr: 5, pl: 5 }}>
        <Grid container>
          <Grid container item xs={6} alignItems='flex-end'>
            <Typography variant='h3'>{strings.deny_dialog_title}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent='flex-end'>
              <IconButton onClick={() => handleClose(undefined)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item xs={12} mt={2}>
            <Grid container item sx={{ aspectRatio: '9/16', maxHeight: '45vh' }} className='ugc-subscription-dialog-video-wrapper' xs={5} alignItems='center'>
              <ReactPlayer
                url={[influencer.contentLinks[0].link]}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                onContextMenu={e => e.preventDefault()}
                controls
              />
            </Grid>
            <Grid item xs={7} pl={2}>
              <FeedbackForm influencer={influencer} campaignId={campaignId} brandId={brandId} handleClose={handleClose} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

type FeedbackFormProps = {
  influencer: UgcContent
  campaignId: string
  brandId: string
  handleClose: (influencerId?: string) => void
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ influencer, campaignId, brandId, handleClose }) => {
  const [selectedReasons, setSelectedReasons] = useState([''])
  const [loading, setLoading] = useState(false)
  const { reload } = useContext(ContentCampaignOverviewContext)
  const { enqueueSnackbar } = useSnackbar()
  const strings = useStrings()

  const sendFeedback = async () => {
    setLoading(true)
    try {
      const { influencerId } = influencer
      await rootStore.campaignStore.manageInfluencerContent(
        brandId,
        campaignId,
        influencerId,
        false,
        selectedReasons.filter(s => s != '')
      )
      await reload()
      handleClose(influencerId)
    } catch (e) {
      console.error(e)
      enqueueSnackbar(strings.error, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid container justifyContent='center' alignItems='center'>
      {selectedReasons.map((selectedReason, i) => (
        <React.Fragment key={i}>
          <Grid mb={2} item xs={selectedReasons.length === 1 ? 12 : 10}>
            <TextField
              label={selectedReason ? strings.deny_dialog_feedback(i + 1) : strings.deny_dialog_choose_feedback}
              variant='outlined'
              size='medium'
              fullWidth
              onChange={event => {
                const value = event.target.value
                setSelectedReasons(p => {
                  const newReasons = [...p]
                  newReasons[i] = value
                  return newReasons
                })
              }}
            />
          </Grid>
          {selectedReasons.length > 1 && (
            <Grid item xs={2} mb={2}>
              <IconButton
                onClick={() =>
                  setSelectedReasons(p => {
                    const newReasons = [...p]
                    newReasons.splice(i, 1)
                    return newReasons
                  })
                }>
                <Delete />
              </IconButton>
            </Grid>
          )}
        </React.Fragment>
      ))}
      <Grid container item xs={12} justifyContent='center'>
        {selectedReasons.length < 5 && (
          <IconButton onClick={() => setSelectedReasons(p => [...p, ''])}>
            <img src={Add} alt='.' />
          </IconButton>
        )}
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} textAlign='center'>
          {strings.deny_dialog_max}
        </Grid>
        <Grid item xs={12} textAlign='center'>
          {strings.deny_dialog_disclaimer}
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Grid container justifyContent='flex-end'>
          <LoadingButton loading={loading} onClick={sendFeedback} variant='contained' disabled={!selectedReasons.join().length}>
            {strings.deny_dialog_send_feedback}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}
